@import '../css/shared';
.private-sales-page {
    .main-banner {
        flex-direction: column;
        .message {
            position: relative;
            
            
        }   
    }
    @include media-breakpoint-up(lg) {
        .main-banner {
            flex-direction: row;
            .message {
                position: absolute;
            }
        }
    }
}